<template>
  <div
    class="hidden md:flex w-3/5 bg-teal-900 from-teal-800 bg-[url('@/assets/images/login-bg.png')] bg-no-repeat bg-contain bg-center"
  >
    <div class="flex">
      <div class="mx-4 mt-2">
        <ElementsLogoContextLabsLogo></ElementsLogoContextLabsLogo>
      </div>
    </div>
  </div>
</template>
